import { template as template_69f0ed92ce6f4cb8baae5d98fe21eca1 } from "@ember/template-compiler";
const FKText = template_69f0ed92ce6f4cb8baae5d98fe21eca1(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
