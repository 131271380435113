import { template as template_5e9306476a2343edb900eadac56ae6ed } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_5e9306476a2343edb900eadac56ae6ed(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
